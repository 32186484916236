import { axios } from '@/utils/request'

export function getList(params) {
  return axios({
    url: '/community/ysFaultdiagram/list',
    method: 'get',
    params
  })
}

export function add(params) {
  return axios({
    url: '/community/ysFaultdiagram/add',
    method: 'post',
    data: params
  })
}

export function del(params) {
  return axios({
    url: '/community/ysFaultdiagram/delete',
    method: 'delete',
    params: params
  })
}

export function delSome(params) {
  return axios({
    url: '/community/ysFaultdiagram/deleteBatch',
    method: 'delete',
    params: params
  })
}

export function put(params) {
  return axios({
    url: '/community/ysFaultdiagram/edit',
    method: 'put',
    data: params
  })
}